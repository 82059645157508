<template>
    <page :loading="loading">
        <div slot="head" class="flex-col">
            <!-- <div class="flex-item flex-fill" style="text-align: center;">
                <el-input v-model="table.search.keyword" placeholder="请输入搜索关键字" style="width:300px" @keydown.enter.native="LoadDatas()">
                    <el-button slot="append" icon="el-icon-search" @click="LoadDatas()" />
                </el-input>
            </div> -->
            <div class="flex-item flex-right">
                <el-button type="primary" style="width:100px" @click="table.form = {sort:table.search._count+1,give_integral:0,status:0},$refs['page-dialog'].open()">添加</el-button>
            </div>
        </div>

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" />
            <el-table-column prop='integral'                       label='知识币' align='center' />
            <el-table-column prop='give_integral'                  label='赠送知识币' align='center' />
            <el-table-column prop='amount'                         label='金额' align='center' />
            <el-table-column prop='sort'                           label='排序' align='center' />
            <el-table-column :formatter="statusFormat"   		   label='状态' align='center' prop='status'/>
            <el-table-column prop='created_at'                     label='创建时间' align='center' />
            <el-table-column prop='updated_at'                     label='更新时间' align='center' />
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="table.form = Object.assign({},scope.row),$refs['page-dialog'].open()" type="text" size="small">编辑</el-button>
                    <el-button @click="ActionDel(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </page-table>

        <page-dialog ref="page-dialog" :loading="loading">
            <el-form ref="form">
                
                <el-form-item label="知识币">
                    <el-input v-model="table.form.integral" type="number" placeholder="请输入知识币" />
                </el-form-item>
                <el-form-item label="赠送知识币">
                    <el-input v-model="table.form.give_integral" type="number" placeholder="请输入赠送知识币" />
                </el-form-item>
                <el-form-item label="金额">
                    <el-input v-model="table.form.amount" type="number" placeholder="请输入金额" />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="table.form.sort" type="number" placeholder="请输入排序" />
                </el-form-item>
                <el-form-item label="状态">
                	<el-select v-model="table.form.status" placeholder="请选择状态">
                		<el-option label="正常" :value="0" />
                		<el-option label="禁用" :value="1" />
                	</el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="ActionSave(table.form)">保存</el-button>
            </div>
        </page-dialog>
    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
					_order: 'sort',
					_isreverse: 0,
                }
            },
			statusList:['正常','禁用'],
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
		//状态格式化
		statusFormat(row) {
		  return this.statusList[row.status];
		},
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('app-recharge',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

        /**
         * 保存
         * @param item 要保存的数据
         * **/
        ActionSave(item){
            this.loading = true;
            this.$save('app-recharge',item).then(res=>
            {
                this.loading = false , this.LoadDatas(),this.$notify({message: res.msg || '操作成功'}) , this.$refs['page-dialog'].close();
            }).catch(err=>
            {
                this.loading = false , this.$notify.error({message: err || '网络请求错误'});
            });
        },

        /**
         * 删除
         * @param item 要删除的数据
         * **/
        ActionDel(item){
            if( item.id ){
                this.loading = true;
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    this.$del('app-recharge/'+item.id).then(res=>{
                        this.loading = false , this.LoadDatas() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                }).catch(()=>{
                    this.loading = false;
                })
            }
        }
    },
}
</script>